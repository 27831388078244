<template lang="pug">
.login
  form
    input(
      type="text"
      placeholder="Username"
      v-model="credentials.username"
    )
    input(
      type="password"
      placeholder="Password"
      v-model="credentials.password"
    )
    button(
      type="submit"
      @click.prevent="login"
    ) Login
</template>

<script>
import { reactive, onMounted } from 'vue'
import store from '@/store'

export default {
  setup () {
    const credentials = reactive({
      username: '',
      password: ''
    })

    onMounted(async () => {
      await store.dispatch('login')
    })

    const login = async () => {
      const { username, password } = credentials

      if (username && password) {
        await store.dispatch('login', credentials)
      }
    }

    return {
      credentials,
      login
    }
  }
}
</script>

<style lang="scss">
.login {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  form {
    display: flex;
    flex-direction: column;

    & > input {
      text-align: center;
    }
  }
}
</style>
