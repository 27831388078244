import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/:invoice',
    name: 'Invoice',
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/Invoice.vue')
  },
  {
    path: '/create',
    name: 'Create',
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/Create.vue')
  },
  {
    path: '/update/:invoice',
    name: 'Update',
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/Update.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
