<template lang="pug">
login(v-if="!state.token")
router-view(v-else)
</template>

<script>
import store from '@/store'
import Login from '@/components/Login'

export default {
  components: { Login },
  setup () {
    return {
      state: store.state
    }
  }
}
</script>

<style lang="scss">
html,
body {
  margin: 0;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
</style>
