<template lang="pug">
.home
  .home__invoice(
    v-for="invoice in state.invoices"
  )
    span.home__title {{ getInvoiceTitle(invoice) }}

    .home__actions
      router-link(
        :to="getUpdateURL(invoice)"
      ) Update

      router-link(
        :to="getInvoiceURL(invoice)"
        target="_blank"
      ) Open

      span(
        @click="deleteInvoice(invoice)"
      ) Delete

  router-link.home__create(
    to="/create"
  ) Create new invoice
</template>

<script>
import { onMounted, reactive } from 'vue'
import store from '@/store'

export default {
  name: 'Home',
  setup () {
    const state = reactive({
      invoices: store.state.invoices
    })

    onMounted(async () => {
      const invoices = await store.dispatch('loadInvoices')
      state.invoices = invoices
    })

    const getInvoiceURL = invoice => {
      return `${window.location.origin}/${invoice._id}`
    }

    const getUpdateURL = invoice => {
      return `/update/${invoice._id}`
    }

    const deleteInvoice = async invoice => {
      const doDelete = confirm('Do you really want to delete this invoice')

      if (doDelete) {
        await store.dispatch('deleteInvoice', invoice._id)
        const invoices = await store.dispatch('loadInvoices')
        state.invoices = invoices
      }
    }

    const getInvoiceTitle = invoice => {
      const { title, date } = invoice
      const newDate = new Date(date)
      const day = newDate.getDate()
      const month = newDate.getMonth() + 1
      const year = newDate.getFullYear()
      return `${title} - ${day}/${month}/${year}`
    }

    return {
      state,
      getInvoiceURL,
      getUpdateURL,
      deleteInvoice,
      getInvoiceTitle
    }
  }
}
</script>

<style lang="scss">
.home {
  padding: 2rem;
  font-size: 1.5rem;

  &__invoice {
    display: flex;
    justify-content: space-between;
    width: 100%;
    border-bottom: solid black 1px;
    color: black;
    text-decoration: none;
    box-sizing: border-box;

    &:first-child {
      border-top: solid black 1px;
    }
  }

  &__title {
    padding: 0.5rem;
  }

  &__actions {
    display: flex;

    * {
      display: block;
      cursor: pointer;
      padding: 0.5rem;

      &:hover {
        background-color: black;
        color: white;
      }
    }

    * + * {
      margin-left: 0.5rem;
    }

    a {
      color: black;
      text-decoration: none;
    }
  }

  &__create {
    display: block;
    padding: 0.5rem;
    border-bottom: solid 1px black;
    color: black;
    text-decoration: none;

    &:hover {
      background-color: black;
      color: white;
    }
  }
}
</style>
