import { createStore } from 'vuex'
import axios from 'axios'
import config from '@/config'

const { baseURL } = config
const socket = axios.create({ baseURL })

export default createStore({
  state: {
    token: null,
    invoices: []
  },

  mutations: {
    setInvoices (state, invoices) {
      state.invoices = invoices
    },

    setToken (state, token) {
      state.token = token
    }
  },

  actions: {
    createInvoice: async ({ state, dispatch }, data) => {
      await socket.post('/create', data, {
        headers: {
          authorization: state.token
        }
      })

      await dispatch('loadInvoices')
    },

    updateInvoice: async ({ state, dispatch }, data) => {
      const id = data._id
      delete data._id
      await socket.post(`/update/${id}`, data, {
        headers: {
          authorization: state.token
        }
      })

      await dispatch('loadInvoices')
    },

    loadInvoices: async ({ state, commit }) => {
      const { data } = await socket.get('/', {
        headers: {
          authorization: state.token
        }
      })

      commit('setInvoices', data)
      return data
    },

    deleteInvoice: async ({ state }, id) => {
      await socket.delete(`/delete/${id}`, {
        headers: {
          authorization: state.token
        }
      })
    },

    loadPDF: async ({ state }, id) => {
      const { data } = await socket.get(`/pdf/${id}`, {
        headers: {
          authorization: state.token
        }
      })

      return data
    },

    login: async ({ commit }, credentials) => {
      try {
        const { data } = await socket.post('/login', credentials)
        commit('setToken', data.token)
        return true
      } catch (error) {
        return false
      }
    }
  }
})
